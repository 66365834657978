import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import login from '@/views/shopping_mall/login/login'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.bundle.js'

import ElementPlus from 'element-plus'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import 'element-plus/dist/index.css'
import { Stepper,Button,Search,Checkbox,CheckboxGroup,Pagination,Icon,Badge,Dialog,Collapse,CollapseItem,Grid,GridItem,Uploader,Form,Field,CellGroup } from 'vant';
router.beforeEach((to, from, next) => {
    /* 路由发生变化修改页面title */
    if (to.meta.title) {
      document.title = to.meta.title;
    }
    next();
})
router.afterEach((to,from,next) => {
  window.scrollTo(0,0);
});

import bootstrap from '@/bootstrap.js'
import tools from '@/utils/tools.js'
bootstrap({router, store})
import { createMetaManager} from 'vue-meta'
const app = createApp(App)
app.use(store)
app.use(router)
app.use(createMetaManager(false, {
    meta: { tag: 'meta', nameless: true }
}))
app.use(Icon)
app.use(Button) 
app.use(Search)
app.use(Checkbox)
app.use(CheckboxGroup)
app.use(Pagination)
app.use(Badge)
app.use(Collapse)
app.use(CollapseItem)
app.use(Grid)
app.use(GridItem)
app.use(Stepper)
app.use(Uploader)
app.use(Form)
app.use(Field)
app.use(CellGroup)

app.use(ElementPlus,{locale: zhCn,})
app.use(Dialog) 
app.mount('#app')

import {httpGet, httpPost, httpAll} from '@/utils/request'

app.config.globalProperties.$tools = tools
app.config.globalProperties.$httpGet = httpGet
app.config.globalProperties.$httpPost = httpPost
app.config.globalProperties.$httpAll = httpAll
// app.config.globalProperties.$message = message