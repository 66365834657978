<template>
    <div style="width:100%; background-color: #fff;margin-top: 50px;">
        <div class="pop_footer" v-if="footerStatus">
            <div class="title">
                马上评估需求，获取方案及报价
            </div>
            <ul>
                <li>
                    <div>
                        <span style="color:#D0171C">*</span>姓名<b>:</b>
                    </div>
                    <div class="box">
                        <input type="text" v-model="userName" placeholder="请输入您的姓名">
                    </div>
                </li>
                <li>
                    <div>
                        <span style="color:#D0171C">*</span>联系电话<b>:</b>
                    </div>
                    <div class="box">
                        <input type="text" v-model="userPhone" placeholder="请输入您手机号">
                    </div>
                </li>
                <!-- <li>
                    <div>
                        <span style="color:#D0171C">*</span>公司<b>:</b>
                    </div>
                    <div class="box">
                        <input type="text" v-model="userEnterprise" placeholder="请输入您的公司名称">
                    </div>
                </li> -->
                <!-- <li>
                    <div>
                        <span style="color:#D0171C">*</span>邮箱<b>:</b>
                    </div>
                    <div class="box">
                        <input type="text" v-model="mailbox" placeholder="请输入您的邮箱">
                    </div>
                </li> -->
            </ul>
            <div>
                <div>
                    <span style="color:#D0171C">*</span>备注需求
                </div>
                <div class="text_box">
                    <textarea
                        style="width:100%;height:100%;border:none;resize:none;background: none;padding: 10px;font-size: 13px;"
                        v-model="textValue" id="" cols="30" rows="10" placeholder="请输入您的资讯需求">
                    </textarea>
                </div>
            </div>
            <div class="pop_footer_off" @click="footerStatus = false">×</div>
            <div class="btn_box" @click="submitFun">
                提交
            </div>
        </div>
        <div class="pop_footer" v-if="ewmStatus">
            <div class="title f24">
               添加专属客服
            </div>
            <div class="pop_main">
                <img src="@/assets/image/qrCode.jpg" alt="">
                <div class="pop_text">客服微信</div>
            </div>
            
            <div class="pop_footer_off" @click="offPopFun">×</div>
        </div>
        <!-- 尾部 -->
        <!-- 底部内容 -->
        <div style="width:100%;">
            <div class="footer_list">
                <div class="footer_list_box">
                    <!-- <div class="left">
                        <img src="@/assets/image/bufanlogo.png" alt="">
                    </div> -->
                    <div class="cont">
                        <ul class="info_list">
                            <li>
                                <span class="f15">关于我们</span>
                                <router-link class="f12" to="/realTimeInfo?type=1&name=不凡简介"
                                    target="_blank">不凡简介</router-link>
                                <router-link class="f12" to="/realTimeInfo?type=2&name=不凡文化"
                                    target="_blank">不凡文化</router-link>
                                <router-link class="f12" to="/realTimeInfo?type=3&name=不凡历程"
                                    target="_blank">不凡历程</router-link>
                                <router-link class="f12" to="/realTimeInfo?type=4&name=不凡动态"
                                    target="_blank">不凡动态</router-link>
                            </li>
                            <li>
                                <span class="f15">行业解决方案</span>
                                <router-link class="f12" to="/classiFication?id=449&name=category_id"
                                    target="_blank">自动化装备行业</router-link>
                                <router-link class="f12" to="/classiFication?id=460&name=category_id"
                                    target="_blank">新能源储能行业</router-link>
                                <router-link class="f12" to="/classiFication?id=467&name=category_id"
                                    target="_blank">家用电器行业</router-link>
                                <router-link class="f12" to="/classiFication?id=468&name=category_id"
                                    target="_blank">无人机行业</router-link>
                                <router-link class="f12" to="/classiFication?id=456&name=category_id"
                                    target="_blank">照明灯具行业</router-link>
                            </li>
                            <li>
                                <span class="f15">定制需求</span>
                                <a class="f12" @click="footerStatus = true">联系专属客服</a>
                                <a class="f12" @click="ewmStatus = true">添加客服</a>
                            </li>
                            <li>
                                <span class="f15">一站式清单报价</span>
                                <a class="f12" @click="footerStatus = true">联系专属客服</a>
                                <a class="f12" @click="ewmStatus = true">添加客服</a>
                            </li>
                        </ul>
                    </div>
                    <div class="right">
                        <li>
                            <span>服务时间<b>:</b> 8:00 ~ 22:00</span>
                            <span>电话<b>:</b> 0769-82810622</span>
                            <span>地址<b>:</b> 广东省东莞市寮步镇横坑宝塘路41号东涛大厦6-7层</span>
                        </li>
                        <li>
                            <img src="@/assets/image/icon.jpg" alt="">
                        </li>
                    </div>
                </div>
            </div>
			<div class="footer_bottom">
				<div class="footer_img">
				    <div>
				        <img src="@/assets/image/zpbz.png" alt="">
				        <span>正品保障</span>
				    </div>
				    <div>
				        <img src="@/assets/image/qhbz.png" alt="">
				        <span>货期保证</span>
				    </div>
				    <div>
				        <img src="@/assets/image/zskf.png" alt="">
				        <span>专属客服</span>
				    </div>
				    <div>
				        <img src="@/assets/image/ygcg.png" alt="">
				        <span>阳光采购</span>
				    </div>
					<div>
					    <img src="@/assets/image/yzsfw.png" alt="">
					    <span>一站式服务</span>
					</div>
				</div>
				<hr/>
				<div class="bottom_text">
					<div>Copyright© 2008-2023 版权所有  东莞市不凡电子有限公司  网站地图  工商执照  第二类医疗器械经营备案：粤东食药监械经营备20200140号<br/>
粤公网安备32059002003750号 粤ICP证B2-20140259 <a href="https://beian.miit.gov.cn/" target="_blank" style="color: #8B8D93;">粤ICP备19086579号-1</a> </div>
					<img src="@/assets/image/bottom_logo.png" alt="" style="width: 134px;height: 61px;">
				</div>
			</div>
        </div>
    </div>
</template>


<script setup>
import { ref, toRefs, watch, defineProps, defineEmits, toRef } from 'vue';
import { ElMessage, ElMessageBox } from 'element-plus'
import { httpPost } from '@/utils/request';
import { Toast } from 'vant';
const props = defineProps({
    ewm_status:{
        type:Boolean,
        default:false
    }
});
const { ewm_status } = toRefs(props);  //父组件传递过来
const emit = defineEmits(['offFun']);   //定义子组件传递过去的fun
let ewmStatus = ref(false);

watch(ewm_status,status => {
    // console.log(status,'hhhh')
    ewmStatus.value = status;
});

const mailVerify = /^.+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/;  //邮箱验证
let footerStatus = ref(false);

let userName = ref('');
let userPhone = ref('');

let mailbox = ref('');
let textValue = ref('');

const submitFun = () => {
    ElMessageBox.confirm(
        '是否确认提交需求？',
        '提交需求',
        {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'warning',
        }
    ).then(() => { 
        if(userName.value == '') return Toast('请填写您的姓名');
        if(userPhone.value == '') return Toast('请填写您的联系方式');
        // if(mailbox.value == '') return Toast('请填写您的联系邮箱');
        // if (!mailVerify.test(mailbox.value)) return Toast('邮箱格式不正确');
        let data = {
            name:userName.value,
            mobile:userPhone.value,
            // company:userEnterprise.value,
            remarks:textValue.value,
            // email:mailbox.value
        }
        httpPost('/api/index/subDemand', data).then((res) => {
            if (res.code == 0) {
                ElMessage({
                    type: 'info',
                    message: res.msg,
                })
                return
            }
            if (res.code == 1) {
                ElMessage({
                    type: ' success',
                    message: res.msg,
                })
                setTimeout(() => { footerStatus.value = false }, 2000);
            }
        })
    }).catch(() => {
        ElMessage({
            type: 'info',
            message: '取消需求提交',
        })
    })
}
const offPopFun = () => {
    ewmStatus.value = false;
    emit('offFun', ewmStatus.value)
}
</script>

<style scoped>
/* 尾部布局 */
.footer {
    width: 1440px;
    height: auto;
    margin: 10px auto;
}

.footer_img {
    width: 1280px;
    height: 150px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
}

.footer_img div {
    display: block;
    width: 25%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer_img div img {
    width: 40px;
    height: 40px;
}
.footer_img div span {
    font-size: 16px;
    font-weight: 600;
    margin-left: 15px;
	color: #8B8D93;
}

.footer_title {
    width: 100%;
    height: 50px;
    line-height: 60px;
    font-size: 21px;
    text-align: center;
    color: #333;
}

.arrow {
    width: 100%;
    height: 50px;
    text-align: center;
}

.arrows {
    width: 100%;
    /* height: 45px; */
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
}

.arrows_btn {
    width: 133px;
    height: 35px;
    border-radius: 30px;
    letter-spacing: 2px;
    color: #fff;
    background-color: #D0161B;
    cursor: pointer;
    font-size: 21px;
}

.arrows_btn:hover {
    background-color: #E55D5D;
}

.footer_list {
    width: 1200px;
    height: 196px;
    margin: 15px auto;
    display: flex;
    align-items: center;
    color: #333;
}

.footer_list_box {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
	justify-content: center;
}

.footer_list_box .cont {
    width: 600px;
    height: 100%;
    padding: 0 30px;
    padding-top: 30px;
}

.footer_list_box .left {
    width: 200px !important;
}

.footer_list_box .left,
.footer_list_box .right {
    width: 400px;
    height: 100%;
    display: flex;
    font-size: 12px;
}

.footer_list_box .right {
    height: 150px;
    border-left: 1px solid #999;
}

.footer_list_box .right li:nth-child(1) {
    width: 237px;
    height: 120px;
    align-items: flex-start;
}

.footer_list_box .right li:nth-child(2) {
    width: 100px;
}

.footer_list_box .right li:nth-child(2) img {
    width: 100px
}

.footer_list_box .left {
    display: flex;
    align-items: center;
    justify-content: center;
}

.footer_list_box img {
    width: 188px;
}

.info_list {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
}

.info_list li {
    display: flex;
    flex-direction: column;
}

.info_list li span {
    font-weight: 600;
    margin-bottom: 15px;
}

.info_list li a {
    margin: 2px 0;
    color: #767676;
    font-weight: 500;
}

.info_list li img {
    width: 21px;
    height: 19px;
    margin-right: 5px;
}

.cont .info_list_text {
    font-size: 12px;
    width: 100%;
    height: 24px;
    line-height: 24px;
}

.footer_list_box .right {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.footer_list_box .right li {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.footer_list_box .right li a {
    color: #333;
    font-size: 14px;
}

.footer_list_box .right .right_box {
    width: 145px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 12px;
}

.footer_list_box .right .right_box img {
    width: 84px;
    height: 84px;
    margin-bottom: 10px;
}

.pop_footer {
    width: 350px;
    height: 600px;
    background: #fff;
    overflow: hidden;
    border-radius: 10px;
    position: relative;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 99;
    box-shadow: 0 0 10px #c0c0c0;
    padding: 20px;
}

.pop_main {
    width: 300px;
	height: 552px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    /* background: #999; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.pop_main img {
    width: 250px;
    height: 552px;
}
.pop_text {
    width: 100%;
    font-size: 22px;
    text-align: center;
}
.pop_footer_off {
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    background: #D0161B;
    color: #fff;
    position: absolute;
    right: 5px;
    top: 5px;
    cursor: pointer;
    overflow: hidden;
    border-radius: 5px;
    font-size: 30px;
}

.pop_footer .title {
    width: 100%;
    height: 50px;
    line-height: 50px;
    font-weight: 600;
    text-align: center;
    font-size: 18px;
}

.pop_footer ul {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.pop_footer ul li {
    width: 47%;
    margin: 10px 0;
}

.pop_footer ul li .box {
    width: 100%;
    height: 30px;
    background: #E0F0FC;
}

.pop_footer ul li .box [type="text"] {
    width: 100%;
    line-height: 30px;
    height: 100%;
    background: none;
    padding-left: 10px;
    font-size: 13px;
}

.text_box {
    width: 100%;
    height: 100px;
    background: #E0F0FC;
}

.btn_box {
    width: 150px;
    height: 34px;
    line-height: 34px;
    text-align: center;
    margin: 20px auto;
    letter-spacing: 10px;
    text-indent: 10px;
    border-radius: 10px;
    background: #D0161B;
    color: #fff;
    cursor: pointer;
}
.footer_bottom{
	background-color: #2D313F;
	padding: 20px 0px;
}
.footer_bottom hr{
	width: 1280px;
	height: 1px;
	background-color: #FFFFFF;
	margin: auto;
}
.footer_bottom .bottom_text{
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	text-align: center;
	font-size: 9px;
	color: #8B8D93;
	margin: 30px auto 0px;
}
</style>