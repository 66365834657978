// import {loadGuards} from '@/utils/routerUtil'
import {loadInterceptors} from '@/utils/request'
// import guards from '@/router/guards'
import interceptors from '@/utils/axios-interceptors'

/**
 * 启动引导方法
 * 应用启动时需要执行的操作放在这里
 * @param router 应用的路由实例
 * @param store 应用的 vuex.store 实例
 * @param message
 */
function bootstrap({router, store}) {
    // 加载 axios 拦截器
    loadInterceptors(interceptors, {router, store})
    // 加载路由守卫
    // loadGuards(guards, {router, store})
}

export default bootstrap
