import qs from 'qs'
import {removeAuthorization} from "@/utils/request.js"; //移除登录验证数据

const request = {
    /**
     * 发送请求之前做些什么
     * @param config axios config
     * @param options 应用配置 包含: {router, store, message}
     * @returns {*}
     */
    onFulfilled(config, options) {
        const {message} = options
        const {url} = config
        // console.log('config',config)

        let token = localStorage.getItem('token')
        if (config.method === 'post') {
            config.data.token = token
            config.data = qs.stringify(config.data);
            config.headers['platform'] = 'H5';
        }
        if (config.method === 'get') {
            config.params.token = token
        }
        return config
    },

    /**
     * 请求出错时做点什么
     * @param error 错误对象
     * @param options 应用配置 包含: {router, store, message}
     * @returns {Promise<never>}
     */
    onRejected(error, options) {
        console.log('请求出错')
        console.log('error',error)
        console.log('options',options)
        const {message} = options
        message.error(error.message)
        return Promise.reject(error)
    }
}

const response = {
    /**
     * 响应数据之前做点什么
     * @param response 响应对象
     * @param options 应用配置 包含: {router, store, message}
     * @returns {*}
     */
    onFulfilled(response, options) {
        const {message} = options
        const result = response.data
        // console.log('响应数据')
        // console.log('message',message)
        // console.log('result',result)
        if (result.code === 1) {
            // result.msg && message.success(result.msg)
            return Promise.resolve(result)
        }
        if (result.code === 0) {
            // result.msg && message.error(result.msg)
            return Promise.resolve(result)
        }
        return Promise.resolve(result)
    },

    /**
     * 响应出错时执行
     * @param error 错误对象
     * @param options 应用配置 包含: {router, store, message}
     * @returns {Promise<never>}
     */
    onRejected(error, options) {
        const {router} = options
        const {response} = error
        console.log(error)
        let status = response.data.code;
        let responseMsg = response.data.msg
        let msg
        switch (status) {
            case 401:
                msg = responseMsg ? responseMsg : '登录过期'
                break;
            case 403:
                msg = responseMsg ? responseMsg : '请求被拒绝'
                break;
            case 500:
            default:
                msg = responseMsg ? responseMsg : '服务器开小差了'
                break
        }
        let path = router.currentRoute.value.path;
        path = path.split('/')
        // console.log(path[1])
        // console.log('开来看啊看啊',response.status)
        if (status === 401 && path[1] !='logins') {
            sessionStorage.removeItem('token')
            removeAuthorization()

            setTimeout(() => {
                router.push('/login')
            }, 100)
            return false
        }

        return false
    }
}

export default {
    request: [request], // 请求拦截
    response: [response] // 响应拦截
}
