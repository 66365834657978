import {
	createRouter,
	createWebHistory,
	createWebHashHistory
} from 'vue-router'

const routes = [{
		path: '/',
		name: 'shopping_home',
		meta: {
			title: '不凡商城-中高端工具仪器一站式采购平台'
		},
		component: () => import('@/views/shopping_mall/home/Home.vue')
	},
	{
		path: '/sceneCenter',
		name: 'SceneCenter',
		meta: {
			title: '不凡商城-场景中心'
		},
		component: () => import('@/views/shopping_mall/sceneCenter/sceneCenter.vue')
	},
	{
		path: '/mine',
		name: 'mine',
		meta: {
			title: '不凡商城-用户中心'
		},
		component: () => import('@/views/shopping_mall/mine/mine.vue'),
		// children:[
		//   {
		//     path: 'mineOption',
		//     name: 'mineOption',
		//     meta:{
		//           title: '个人中心设置'
		//     },
		//     component:import('@/views/mine/components/mineOption.vue')
		//   }
		// ]
	},
	{
		path: '/mine/mineOption',
		name: 'mineOption',
		meta: {
			title: '不凡商城-个人中心设置'
		},
		component: () => import('@/views/shopping_mall/mine/components/mineOption.vue')
	},
	{
		path: '/mine/quotation',
		name: 'quotation',
		meta: {
			title: '不凡商城-报价中心'
		},
		component: () => import('@/views/shopping_mall/quotation_center/quotation.vue')
	},
	{
		path: '/mine/afterSale',
		name: 'afterSale',
		meta: {
			title: '不凡商城-售后审核'
		},
		component: () => import('@/views/shopping_mall/afterSale_center/afterSale.vue')
	},
	{
		path: '/mine/boliweiMyself',
		name: 'boliweiMyself',
		meta: {
			title: '不凡商城-智采订单管理'
		},
		component: () => import('@/views/shopping_mall/boliweiMyself/boliweiMyself.vue')
	},
	{
		path: '/mine/dataMyself',
		name: 'dataMyself',
		meta: {
			title: '不凡商城-数采订单管理'
		},
		component: () => import('@/views/shopping_mall/dataMyself/dataMyself.vue')
	},
	{
		path: '/mine/reconciliation',
		name: 'reconciliation',
		meta: {
			title: '不凡商城-订单管理'
		},
		component: () => import('@/views/shopping_mall/reconciliation/reconciliation.vue')
	},
	{
		path: '/mine/connection',
		name: 'connection',
		meta: {
			title: '不凡商城-客户中心'
		},
		component: () => import('@/views/shopping_mall/connection/connection.vue')
	},
	{
		path: '/orderCenter',
		name: 'orderCenter',
		meta: {
			title: '不凡商城-订单中心'
		},
		component: () => import('@/views/shopping_mall/order_center/orderCenter.vue'),
	},
	{
		path: '/collection',
		name: 'collection',
		meta: {
			title: '不凡商城-收藏夹'
		},
		component: () => import('@/views/shopping_mall/collection/collection.vue'),
	},
	{
		path: '/orderCenter/orderCenterDetail',
		name: 'orderCenterDetail',
		meta: {
			title: '不凡商城-订单详情'
		},
		component: () => import('@/views/shopping_mall/order_center/components/orderCenterDetail.vue')
	},
	{
		path: '/order',
		name: 'order',
		meta: {
			title: '不凡商城-创建订单信息'
		},
		component: () => import('@/views/shopping_mall/order/order.vue')
	},
	{
		path: '/moreOrder',
		name: 'moreOrder',
		meta: {
			title: '不凡商城-批量下单'
		},
		component: () => import('@/views/shopping_mall/order/components/moreOrder.vue')
	},
	{
		path: '/orderCenter/orderAfterSales',
		name: 'orderAfterSales',
		meta: {
			title: '不凡商城-售后服务'
		},
		component: () => import('@/views/shopping_mall/order_center/components/orderAfterSales.vue')
	},
	{
		path: '/shoppingCart',
		name: 'shoppingCart',
		meta: {
			title: '不凡商城-购物车'
		},
		component: () => import('@/views/shopping_mall/shopping_cart/shoppingCart.vue')
	},
	{
		path: '/login',
		name: 'Login',
		meta: {
			title: '不凡商城-登录'
		},
		component: () => import('@/views/shopping_mall/login/login.vue')
	},
	{
		path: '/register',
		name: 'Register',
		meta: {
			title: '不凡商城-企业注册'
		},
		component: () => import('@/views/shopping_mall/register/register.vue')
	},
	{
		path: '/personal',
		name: 'Personal',
		meta: {
			title: '不凡商城-个人注册'
		},
		component: () => import('@/views/shopping_mall/register/personal.vue')
	},
	{
		path: '/modify',
		name: 'modify',
		meta: {
			title: '不凡商城-忘记密码'
		},
		component: () => import('@/views/shopping_mall/register/modify.vue')
	},
	{
		path: '/classiFication',
		name: 'ClassiFication',
		meta: {
			title: '不凡商城-商品分类'
		},
		component: () => import('@/views/shopping_mall/classiFication/classiFication.vue')
	},
	{
		path: '/classiFicationBF',
		name: 'ClassiFicationBF',
		meta: {
			title: '不凡商城-A-BF品牌'
		},
		component: () => import('@/views/shopping_mall/classiFication/classiFicationBF.vue')
	},
	{
		path: '/logins/mallDetails',
		name: 'MallDetails',
		meta: {
			title: '不凡商城-商品详情'
		},
		component: () => import('@/views/shopping_mall/mall_details/mallDetails.vue')
	},
	{
		path: '/aboutus',
		name: 'AboutUs',
		meta: {
			title: '不凡商城-资讯详情'
		},
		component: () => import('@/views/shopping_mall/footer_info/footer_info.vue')
	},
	{
		path: '/realTimeInfo',
		name: 'realTimeInfo',
		meta: {
			title: '不凡商城-资讯'
		},
		component: () => import('@/views/shopping_mall/real_time_info/real_time_info.vue')
	},
	{
		path: '/login/loginMiddle',
		name: 'loginMiddle',
		meta: {
			title: '不凡商城-登陆中。。。'
		},
		component: () => import('@/views/shopping_mall/login/login_middle.vue')
	},

	// 选型中心
	{
		path: '/model_home',
		name: 'model_home',
		meta: {
			title: '不凡选型中心-中高端工具仪器一站式采购平台'
		},
		component: () => import('@/views/model_center/home/Home.vue')
	},
	{
	  path: '/model_mine',
	  name: 'model_mine',
	  meta:{
	        title: '选型中心-用户中心'
	  },
	  component:() => import('@/views/model_center/mine/mine.vue'),
	},
	{
	  path: '/model_center/model_mineOption',
	  name: 'model_mineOption',
	  meta:{
	        title: '选型中心-个人中心设置'
	  },
	  component:() => import('@/views/model_center/mine/components/mineOption.vue')
	},
	{
		path: '/model_center/mallDetails',
		name: 'model_MallDetails',
		meta: {
			title: '选型中心-商品详情'
		},
		component: () => import('@/views/model_center/mall_details/mallDetails.vue')
	},
	{
	  path: '/model_center/question_center',
	  name: 'question_center',
	  meta:{
	        title: '选型中心-问答中心'
	  },
	  component:() => import('@/views/model_center/question_center/question.vue'),
	},
	{
	  path: '/model_center/questionDetail',
	  name: 'questionDetail',
	  meta:{
	        title: '选型中心-问答中心'
	  },
	  component:() => import('@/views/model_center/question_center/components/questionDetail.vue'),
	},
	{
	  path: '/model_center/contrast_center',
	  name: 'contrast_center',
	  meta:{
	        title: '选型中心-对比中心'
	  },
	  component:() => import('@/views/model_center/contrast_center/contrast.vue'),
	},
	{
	  path: '/model_center/contrastDetail',
	  name: 'contrastDetail',
	  meta:{
	        title: '选型中心-对比中心'
	  },
	  component:() => import('@/views/model_center/contrast_center/components/contrastDetail.vue'),
	},
	{
	  path: '/model_center/scene_center',
	  name: 'scene_center',
	  meta:{
	        title: '选型中心-场景中心'
	  },
	  component:() => import('@/views/model_center/scene_center/scene.vue'),
	},
	{
	  path: '/model_center/sceneDetail',
	  name: 'sceneDetail',
	  meta:{
	        title: '选型中心-方案中心'
	  },
	  component:() => import('@/views/model_center/scene_center/components/sceneDetail.vue'),
	},
]

// const router = createRouter({
//   history: createWebHistory(process.env.BASE_URL),
//   routes
// })
const router = createRouter({
	history: createWebHashHistory(process.env.BASE_URL),
	routes
})

export default router