export default {
    /**
         * 剩余时间格式化
         * @param {Number} t - 剩余多少秒
         * @return {Object}  format - 格式后的天时分秒对象
         */
    format(t) {
        let format = {
            d: '00',
            h: '00',
            m: '00',
            s: '00'
        };
        if (t > 0) {
            let d = Math.floor(t / 86400);
            let h = Math.floor((t / 3600) % 24);
            let m = Math.floor((t / 60) % 60);
            let s = Math.floor(t % 60);
            format.d = d < 10 ? '0' + d : d;
            format.h = h < 10 ? '0' + h : h;
            format.m = m < 10 ? '0' + m : m;
            format.s = s < 10 ? '0' + s : s;
        }
        return format;
    },
    // 时间戳转换日期格式
    timestampToTime(timestamp) {
        if(!timestamp){
            return ''
        }
        var date = new Date(timestamp * 1000),        //时间戳为10位需*1000，时间戳为13位的话不需乘1000
        Y = date.getFullYear() + '-',
        M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-',
        D = (date.getDate()<10 ? '0'+(date.getDate()):date.getDate()) + ' ',
        h = (date.getHours()<10 ? '0'+(date.getHours()):date.getHours()) + ':',
        m = (date.getMinutes()<10 ? '0'+(date.getMinutes()):date.getMinutes()) + ':',
        s = (date.getSeconds()<10 ? '0'+(date.getSeconds()):date.getSeconds());

        return Y+M+D+h+m+s;

    },
    timestampToTimes(timestamp) {
        if(!timestamp){
            return ''
        }
        var date = new Date(timestamp * 1000),        //时间戳为10位需*1000，时间戳为13位的话不需乘1000
        Y = date.getFullYear() + '-',
        M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-',
        D = (date.getDate()<10 ? '0'+(date.getDate()):date.getDate())
        return Y+M+D
    },
    phoneHide(num){
        num = num.split('');
        num[3]='*';
        num[4]='*';
        num[5]='*';
        num[6]='*';
        num = num.join('');
        return num
    },
    //验证邮箱
    checkEmail(emailVal){//邮箱格式
        let check=  /^(\w-*\.*)+@(\w-?)+(\.\w{2,})+$/;
        let status = check.test(emailVal)
        return status
    }
    
}