import axios from 'axios'

//跨域代理前缀
const API_PROXY_PREFIX = 'https://apitest.bufanshop.kcshop.vip'
//测试api
// const API_PROXY_PREFIX = 'https://apitest.bufanshop.kcshop.vip'
//
const BASE_URL = API_PROXY_PREFIX
// import.meta.env.MODE === 'development' ? API_PROXY_PREFIX : import.meta.env.VITE_APP_API_BASE_URL
axios.defaults.baseURL = BASE_URL + '/'
axios.defaults.timeout = 30 * 1000
axios.defaults.withCredentials = true

//并发请求
axios.all = function (promises) {
    return Promise.all(promises)
}

//并发请求返回处理
axios.spread = function (callback) {
    return (arr) => {
        //检测有没有请求失败的，有失败的就不返回数据
        if (arr.some(_item => _item === false)) {
            return false
        }
        callback.forEach((item, index) => {
            return item(arr[index])
        })
    }
}

/**
 * axios请求
 * @param url 请求地址
 * @param params 请求参数
 * @param config
 * @returns {Promise<AxiosResponse<T>>}
 */
async function httpGet(url, params = {}, config = {}) {
    return axios.get(url, {params, ...config})
}

/**
 * axios POST
 * @param url
 * @param params
 * @param config
 * @returns {Promise<AxiosResponse<any>>}
 */
async function httpPost(url, params = {}, config = {}) {
    config['headers'] = {
        'token': localStorage.getItem('token'),
        'Content-Type': 'application/x-www-form-urlencoded'
    };
    return axios.post(url, params, config)
}

/**
 * axios POST FormData
 * @param url
 * @param params
 * @param config
 * @returns {Promise<AxiosResponse<any>>}
 */
async function httpUpload(url, params = {}, config = {}) {
    config['headers'] = {
        'token': localStorage.getItem('token'),
        'Content-Type': 'multipart/form-data; boundary=----WebKitFormBoundaryZjXxz6xGlf2D88lY',
        transformRequest: [data => data],
    };
    // console.log(params,'啥玩意')
    return axios.post(url, params, config)
}

/**
 * axios all
 */

/*
this.$httpAll(
    [this.getGroupList(), this.getDetail(record.id)],
    [
        (group) => this.groupList = group,
        (detail) => {
            this.formData = detail;
            this.form.spinning = false
        }
    ]
)
*/
async function httpAll(func, cb) {
    return axios.all(func).then(axios.spread(cb))
}

/**
 * 设置认证信息
 */
function setAuthorization(token) {
    localStorage.setItem('token', token)
}

/**
 * 移出认证信息
 */
function removeAuthorization() {
    localStorage.removeItem('token')
}

/**
 * 检查认证信息
 * @param authType
 * @returns {boolean}
 */
function checkAuthorization(authType) {
    return !!localStorage.getItem('token');
}

/**
 * 加载 axios 拦截器
 * @param interceptors
 * @param options
 */
function loadInterceptors(interceptors, options) {
    const {request, response} = interceptors
    // 加载请求拦截器
    request.forEach(item => {
        let {onFulfilled, onRejected} = item
        // console.log('onFulfilled',onFulfilled)
        // console.log('onRejected',onRejected)
        if (!onFulfilled || typeof onFulfilled !== 'function') {
            onFulfilled = config => config
        }
        if (!onRejected || typeof onRejected !== 'function') {
            onRejected = error => Promise.reject(error)
        }
        axios.interceptors.request.use(
            config => onFulfilled(config, options),
            error => onRejected(error, options)
        )
    })
    // 加载响应拦截器
    response.forEach(item => {
        let {onFulfilled, onRejected} = item
        if (!onFulfilled || typeof onFulfilled !== 'function') {
            onFulfilled = response => response
        }
        if (!onRejected || typeof onRejected !== 'function') {
            onRejected = error => Promise.reject(error)
        }
        axios.interceptors.response.use(
            response => onFulfilled(response, options),
            error => onRejected(error, options)
        )
    })
}

/**
 * 解析 url 中的参数
 * @param url
 * @returns {Object}
 */
function parseUrlParams(url) {
    const params = {}
    if (!url || url === '' || typeof url !== 'string') {
        return params
    }
    const paramsStr = url.split('?')[1]
    if (!paramsStr) {
        return params
    }
    const paramsArr = paramsStr.replace(/&|=/g, ' ').split(' ')
    for (let i = 0; i < paramsArr.length / 2; i++) {
        const value = paramsArr[i * 2 + 1]
        params[paramsArr[i * 2]] = value === 'true' ? true : (value === 'false' ? false : value)
    }
    return params
}

export {
    httpGet,
    httpPost,
    httpUpload,
    httpAll,
    setAuthorization,
    removeAuthorization,
    checkAuthorization,
    loadInterceptors,
    parseUrlParams
}
