<template>
  <router-view/>
</template>
<style>
@import url(./assets/iconfont/iconfont.css);
@import url(./assets/css/global.css);
@import url(./assets/css/animation.css);
@import url(./static/css/swiper.min.css);
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box !important;
  font-family:Arial, Helvetica, sans-serif;
}
body{
  /* background-color: #F5F5F5; */
  min-width: 1440px;
}
.clearfix:after{/*伪元素是行内元素 正常浏览器清除浮动方法*/
  content: "";
  display: block;
  height: 0;
  clear:both;
  visibility: hidden;
}
.clearfix{
  *zoom: 1;/*ie6清除浮动的方式 *号只有IE6-IE7执行，其他浏览器不执行*/
}
a {
  display: inline-block;
  text-decoration: none;
  color: #333;
  cursor: pointer;
}
a:hover{
  color: #EE0A24 !important;
}
ul,ol,li {
  list-style: none;
}
.van-search {
  padding: 0;
}
input,button,select {
  outline: none;
  border: none;
}
.logins_nav {
    width: 100%;
    height: 130px;
    background: #fff;
    padding-left: 150px;
    padding-top: 35px;
}
.logins_nav img {
    width: 195px;
    height: 60px;
}
.a_active {
  color: #EE0A24 !important;
}
.a_actives {
  color: #EE0A24 !important;
  border-bottom:solid 2px #EE0A24 !important;
}
.a_active_border {
  border:solid 2px #EE0A24 !important;
}
.border_bottom {
  border-bottom:solid 2px #EE0A24 !important;
}

.swiper-container {
  width: 100%;
  height: 100%;
  /* background: #000; */
}
.swiper-slide {
  font-size: 18px;
  color:#fff;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 40px 60px;
}
.parallax-bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 130%;
  height: 100%;
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center;
}
.swiper-slide .title {
  font-size: 41px;
  font-weight: 300;
}
.swiper-slide .subtitle {
  font-size: 21px;
}
.swiper-slide .text {
  font-size: 14px;
  max-width: 400px;
  line-height: 1.3;
}
.swiper-button-white {
  width: 20px;
  height: 30px;
  background-size: cover;
}

.icon-shizhong {
  color: #fff;
}
.swiper-button-prev,.swiper-button-next {
  background: rgba(0,0,0,0.7);  
  /* padding: 5px; */
  border-radius: 5px;
  cursor: pointer;
}
:root {
    --van-blue: 'none' !important;
}


.el-message {
    top: 40% !important;
}
.el-select .el-input.is-focus .el-input__wrapper {
    box-shadow: 0 0 0 1px #D1161D !important;
}
.el-select {
    --el-select-border-color-hover: #D1161D !important;
    --el-select-disabled-border:#D1161D !important;
    --el-select-font-size: var(--el-font-size-base);
    --el-select-close-hover-color: #D1161D !important;
    --el-select-input-color: var(--el-text-color-placeholder);
    --el-select-input-focus-border-color: #D1161D !important;
    --el-select-multiple-input-color:#D1161D !important;
    /* --el-select-input-focus-border-color: var(--el-color-primary); */
    --el-select-input-font-size: 16px;
}
.bg_active {
	background: #D0161B !important;
	color: #fff !important;
	border: none !important;
}
.user_right_box {
  height: 100vh;
  position: fixed;
  top: 0;
  right: -300px;
  z-index: 999;
  display: flex;
}
.right_boxs {
  width: 38px;
  height: 100%;
  background:#595656;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
}
.right_history_box{
  width: 300px;
  height: 100%;
  background: #e5e5e5;
  position: relative;
}
.right_history_box .icon-guanbi {
  background: #595656;
  width: 30px;
  height:30px;
  line-height: 32px;
  color: #fff;
  border-radius: 50%;
  text-align: center;
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}
.right_history_box1 {
  width: 100%;
  height: 100%;
}
.right_history_box1 img {
  width: 200px;
  position: absolute;
  left: 50%;
  top: 40%;
  transform: translate(-50%,-50%);
}
.rights_boxs {
  width: 100%;
  position: relative;
  cursor: pointer;
  text-align: center;
  padding: 10px;
  font-size: 13px;
}
.history_text {
  width: 100%;
  text-align: center;
  position: absolute;
  top: 50%;
  font-size: 18px;
  font-weight: 600;
  color: #666;
  letter-spacing: 1px;
}
.rights_boxs:hover {
  background: #D1161D;
  color: #fff;
}
.shoppingCartBox {
  width: 90px;
  height: 30px;
  line-height: 30px;
  color: #fff;
  text-align: center;
  font-size: 12px;
  position: absolute;
  left: -90px;
  top: 50%;
  margin-top: -15px;
  background: #000;
}
.user_boxs {
  width: 200px;
  height: 100px;
  font-size: 15px;
  background: #fff;
  box-shadow: 0 0 10px #999;
  border-radius: 8px 0 0 8px;
  left: -200px;
  top: -10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #333;
  z-index: 999;
}
.rights_boxs i {
  display: block;
  border-radius: 50%;
  background: #D0161B;
}
.user_boxs img {
  width: 50px;
  height: 50px;
  margin-bottom: 5px;
}
.user_boxs a {
  color: #b30519;
  font-weight: 600;
}
.topNav {
	width: 40px;
	height: 40px;
	text-align: center;
	line-height: 40px;
	border-radius: 50%;
	background: rgba(0, 0, 0, 0.7);
	color: #fff;
	position: fixed;
	bottom: 50px;
	right: 50px;
	z-index: 50;
	cursor: pointer;
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.van-search__field .van-field__left-icon {
  display: flex;
  align-items: center;
}
</style>

