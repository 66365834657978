import {
	createStore
} from 'vuex'
import user from './modules/user.js'
import index from './modules/index.js'

export default createStore({
	state() {
		return {
			count: 0,
		};
	},
	getters: {
		// isLogin: state => Object.keys(state.userinfo).length > 0
		isLogin: state => Object.keys(state.user.userinfo).length > 0
	},
	mutations: {
		updateUserInfo(state, userinfo) {
			state.user.userinfo = userinfo;
			this.commit('saveUserInfoToStorage')
		},
		addCount() {
			this.state.count++;
		},
		minusCount() {
			this.state.count--;
		},
		saveUserInfoToStorage(state) {
			if (state.user.userinfo != undefined) {
				localStorage.setItem('userinfo', JSON.stringify(state.user.userinfo))
			}
		},
		// 更新token
		updateToken(state, token) {
			state.user.token = token;
			this.commit('saveTokenTostorage')
		},
		saveTokenTostorage(state) {
			localStorage.setItem('token', state.user.token)
		},
		// 更新购物车数量
		updateAnount(state, anount) {
			state.index.addCartNum = anount;
			this.commit('saveAnountTostorage')
		},
		saveAnountTostorage(state) {
			localStorage.setItem('anount', state.index.addCartNum)
		},
	},
	actions: {},
	modules: {
		user,
		index
	}
})